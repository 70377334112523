import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { IAccessToken } from '../models/IAccessToken';
import { ApiClient } from './apiClient.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AccessTokenService {
    basePath = '/accessToken';

    constructor(
        private apiClient: ApiClient,
        private router: Router,
    ) {}

    public createAccessToken(userCredentials: unknown): Observable<IAccessToken> {
        const path = `${this.basePath}`;
        return this.apiClient
            .post<IAccessToken>(path, userCredentials)
            .pipe(catchError((error) => this.handleError(error)));
    }

    public createAccessTokenForClientId(clientId: string, scope: string): Observable<IAccessToken> {
        const path = `${this.basePath}/${clientId}?scope=${scope}`;
        return this.apiClient
            .post<IAccessToken>(path, {})
            .pipe(catchError((error) => this.handleError(error)));
    }

    public logout(): Observable<void> {
        const path = `${this.basePath}`;
        return this.apiClient.delete<void>(path);
    }

    private handleError(error: HttpErrorResponse): Observable<never> {
        if (error.status === 401) {
            this.router.navigateByUrl('/auth/logout');
            return throwError(() => new Error('Unauthorized: Navigated to logout'));
        }

        // Handle other error cases
        const errorMessage = error.error?.message || 'An error occurred';
        return throwError(() => new Error(errorMessage));
    }
}
